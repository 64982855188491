@import url('https://fonts.googleapis.com/css2?family=Kiwi+Maru:wght@300;400;500&display=swap');

html {
    box-sizing: border-box;
    font-family: 'Kiwi Maru', serif;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

#root{
    width:100%;
    margin: auto;
    
    .exitBtn{
        position: absolute;
        z-index: 2;
        color: #FFF;
        cursor: pointer;
        right: 4%;
        top: 13%;
        font-size: 3em;
    }

    .midea-mobile-nav{
        width:50%;
        top: 30%;
        right:20%;
        position: absolute;
        z-index: 2;
         a{
             display: block;
             text-align: center;
             margin-bottom: 10px;
             color: #FFF;
             text-decoration: none;
             font-size: 50px;
         }
    }
    
    .light-mode{ 
        a, .midea-mobile-menu{
            color: #000 !important;
        }
    }
    .midea-header{
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 20px;
        padding-bottom: 20px;
        // position: fixed;
        // z-index: 2;
        // background: #FFF;
        // width: 100% !important;
        // border:1px solid #EEE;

        .midea-modal-tint{
            width: 100vw;
            height: 100vh;
            position: absolute;
            top: 0;
            left: 0;
            background: #000;
            opacity: 0.7;
            z-index: 1;
        }

        .midea-nav{
            padding-top:10px;
        }
    }

    .midea-left-column{
        width:50%;
        display:block;
        float: left;
        padding-left: 20px;
    }

    .midea-right-column{
        width:50%;
        display: block;
        text-align: right;
        float: right;
        padding-right: 20px;
       

        a{
            margin-right: 20px;
            color: #FFF;
            text-decoration: none;
        }
    }


    .midea-container{
        height: 100%;
        padding-bottom: 100px;

        .midea-banner{
            position: relative;
            height: 400px;
            overflow: hidden;
            background-size: cover;
            background-repeat: no-repeat;
            

            .midea-title{
                color:#FFF;
                text-align: center;
                position : relative;
                top:35%;
            }
            .midea-bg-video{
                position: relative;
                bottom: 300px;
            }

        }

        .midea-learning-summary{
            background: #EEE;
            position: relative;
            bottom: 15px;
            .midea-learning-intro{
                width:50%;
                margin: auto;
                padding-top: 50px;
                padding-bottom: 50px;
                p{
                    font-size: 1.2em;
                    line-height: 1.7em;
                }
            }

        }

        .midea-repo-count{
            background: #EEE;
            position: relative;
            bottom:20px;
            padding-top: 50px;
            padding-bottom: 50px;
            text-align: center;
        }

        .midea-git-container{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-flow: row ;
            flex-wrap: wrap;
            padding:20px;
            padding-bottom: 100px;

            .midea-git-projects{
                width:47%;
                background: #fefefe;
                border-radius: 5px;
                margin-bottom: 20px;
                padding-left:40px;
                padding-bottom:20px;
                padding-right: 40px;
                -webkit-box-shadow: 6px 4px 15px 0px rgba(58,58,58,0.42); 
                box-shadow: 6px 4px 15px 0px rgba(58,58,58,0.42);

                .midea-title{
                    text-transform: capitalize;
                }

                .midea-desc, .midea-lang{
                    min-height: 20px;
                }

                .midea-desc{
                    margin-bottom: 30px;
                }

                .midea-lang{
                    background: #3FB5EF;
                    color:#FFF;
                    padding:5px;
                }

                button{
                    margin-top:30px;
                    background: #3FB5EF;
                    display: block;
                    padding: 10px;
                    border: none;
                    border-radius: 5px;
                    a{
                        text-decoration: none;
                        color: #FFF;;
                    }   
                }
            }


        }

        .midea-filter{

            text-align: center;
            padding-top:20px;

            .midea-filter-btn{

                cursor: pointer;
                margin-right: 10px;

                button{ 
                    cursor: pointer;
                    margin-bottom:10px;
                    background: #3FB5EF;
                    color:#FFF;
                    border-radius: 4px;
                    border:1px solid #000;
                    padding:5px;
                }
                
                .midea-delete-filter{
                    width:20px;
                    height:20px;
                    margin: 0 ;
                    display: inline-block;
                }

            }
        }
        

        .midea-studies-container{
            display: flex;
            flex-direction: row;
            flex-flow: row ;
            flex-wrap: wrap;
            justify-content: space-between;
            padding:20px;  
            padding-bottom: 100px; 
            
            .midea-studies{
                width:32%;
                flex-shrink: 2;
                // border:1px solid #000;
                margin-bottom: 40px;
                padding: 10px;
                text-align: center;
                background: #fefefe;
                border-radius: 7px;
                -webkit-box-shadow: 6px 4px 15px 0px rgba(58,58,58,0.42); 
                box-shadow: 6px 4px 15px 0px rgba(58,58,58,0.42);
                
                figure{
                    width:120px;
                    height:120px;
                    margin:auto;
                    border-radius: 50%;
                    overflow: hidden;
                }
                .midea-link-btn{
                    width:40%;
                    margin:auto;
                    margin-top: 20px;
                    margin-bottom : 10px;
                    display: block;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    border-radius: 4px;
                    background: #3FB5EF;
                    
                    a{
                        color:#FFF;
                        text-decoration: none;
                    }
                }
            }
        }

        .midea-intro{
            color:#FFF;
            font-size: 1.5em;
            text-align: center;
            margin-top:20%;
            padding-bottom: 200px;
        }

        .midea-youtube{
            background: #FFF;
            padding-top: 100px;
            padding-bottom: 100px;

            .midea-youtube-intro{
                width:50%;
                font-size: 1.2em;
                line-height: 1.7em;
                margin:auto;
                padding-bottom: 100px;
            }

            .midea-youtube-data{

                display: flex;
                flex-direction: row;
                flex-flow: row ;
                flex-wrap: wrap;
                justify-content: space-between;
                padding : 20px;
                padding-top: 100px;
                border-top: 2px solid #EEE;

                .midea-youtube-items{
                    width:46%;
                    border-radius: 5px;  
                    margin-bottom: 20px;
                    background: #fefefe;
                    -webkit-box-shadow: 6px 4px 15px 0px rgba(58,58,58,0.42); 
                    box-shadow: 6px 4px 15px 0px rgba(58,58,58,0.42);

                    .midea-youtube-name{
                        display: inline-block;
                        position: relative;
                        bottom: 40px;
                    }

                    .midea-youtube-thumb{
                        width:120px;
                        height:120px;
                        border-radius: 50%;
                        overflow: hidden;
                        border:2px solid #3FB5EF;
                        display: inline-block;
                        margin-top: 50px;
                    }

                    .midea-youtube-info{
                        display: inline-block; 
                    }

                    button{ 
                        display: block;
                        position: relative;
                        bottom: 40px;
                        border-radius: 5px;
                        padding-top:10px;
                        padding-bottom:10px;
                        width:120px;
                        background:#3FB5EF;


                        a{
                            text-decoration: none;
                            color: #FFF;
                        }
                    }

                }
            }

        }

        .midea-bio-text{
            width:50%;
            margin: auto;
            padding-bottom: 70px;
            padding-top: 50px;

            .special-letter{
                font-size: 3em;
                color:#19B3F1;
            }

            p{  
                font-size: 1.2em;
                line-height: 2em;
            }
        }

        .midea-mobile-menu{
            font-size: 60px;
            color:#FFF !important;
        }


        .midea-bg-cover{
            width:100vw;
            height:100%;
            background: #000 ;
            opacity: 0.7;
            position: absolute;
            top: 0;
            z-index: -1;
        }


        .midea-bg-video{
            position: fixed;
            right: 0;
            bottom: 0;
            min-width: 100vw;
            min-height: 100%;
            z-index: -2;
        }

    }

}

footer{
    width:100%;
    background:#000;
    padding: 20px;
    position: fixed;
    bottom: 0;
    p{
        color:#FFF;
        text-align: center;
    }
}


@media only screen and (min-width: 955px){
    
    .midea-mobile-menu{
        display: none;
    }
}

@media only screen and (max-width: 955px){

    .midea-desktop-menu{
        display: none;
    }
    

}

@media only screen and (min-width:600px) and (max-width:955px){

    .midea-studies{
        width: 45% !important;
    }

}

@media only screen and (max-width: 600px){
   
    .midea-type-text{
        font-size:1em;
    }

    .midea-studies, .midea-git-projects, .midea-youtube-items{
        width: 100% !important;
    }

    .midea-youtube-thumb{
        width:65px !important;
        height:65px !important;
    }

    .midea-youtube-thumb{
        img{
            width:65px !important;
            height:65px !important; 
        }
    }

    .midea-youtube-info{
        width:100px;
    }
    .midea-youtube-name, .midea-youtube-items button{
        bottom: 10px !important;
    }    

    .midea-intro .midea-type-text{
        font-size:0.7em;
        margin-top: 50%;
    }

    #root{
        height:100vh;
    }
}